<template>
    <a-form-model
        ref="recordEditForm"
        :model="form"
        :rules="rules"
        class="add-upload-modal"
    >
      <div class="link-image">
        <div v-if="!isImgChanged">
          <a-tooltip title="Copy to clipboard" placement="bottom">
            <a-icon type="copy" class="text-primary" @click="clipboardHandle(form.full_path)" style="cursor: pointer; font-size: 16px; padding: 2px 8px 0 0;" />
          </a-tooltip>
          <a :href="form.full_path" target="_blank">{{ urlShortening(form.full_path) }}</a>
        </div>
        <div v-else>
          <p>New link will be available later.</p>
        </div>
      </div>
      <a-form-model-item>
        <a-upload
          list-type="picture-card"
          class="file-uploader"
          style="display: flex; justify-content: center; margin: 0px;"
          :show-upload-list="false"
          @change="fileChange"
          >
            <img v-if="form.mime_type.indexOf('image') !== -1" :src="form.img_url" alt="file image" style="height: 146px; border-radius: 4px;" />
              <div v-else>
                <div>
                  <p>
                    <a-icon type="paper-clip" class="ant-upload-text"  style="font-size: 42px" />
                  </p>
                </div>
              </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item ref="name" label="Friendly name" prop="friendly_name">
            <a-input v-model="form.friendly_name" placeholder="Large input" />
        </a-form-model-item>
        <hr>
          <a-row>
            <a-col class="text-left" :span="6">
              <span>
                <a-popconfirm
                  title="Sure to delete?"
                  @confirm="deleteRecord"
                >
                  <a href="javascript:;">
                    <CustomButton>Delete</CustomButton>
                  </a>
                  <!-- <a href="javascript:;"><a-button>Delete</a-button> </a> -->
                </a-popconfirm>
              </span>
            </a-col>
            <a-col class="text-right" :span="18">
              <a-button type="primary" @click="onSubmit">
                Update
              </a-button>
              <CustomButton style="margin-left: 10px;" @click="closeEditModal">
                Cancel
              </CustomButton>
              <!-- <a-button style="margin-left: 10px;" @click="closeEditModal">
                Cancel
              </a-button> -->
            </a-col>
          </a-row>
    </a-form-model>
</template>
<script>
import apiClient from '@/services/axios'
import pushmix from '@/services/pushmix.js'
import CustomButton from '@/components/custom/button/CustomButton'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'addRecord',
  mixins: [pushmix],
  props: ['recordObj'],
  components: {
    CustomButton,
  },
  data() {
    return {
      form: {
        friendly_name: this.recordObj.name,
        img_url: this.recordObj.full_path,
        mime_type: this.recordObj.mime_type,
        full_path: this.recordObj.full_path,
        img_file: undefined,
      },
      isImgChanged: false,
      rules: {
        // friendly_name: [{ required: true, message: 'Please input friendly name', trigger: 'blur' }],
      },
    }
  },
  methods: {
    urlShortening(url) {
      const conformity = /(https?:\/\/.*?)([/$].*)/.exec(url)
      const path = conformity[2]
      return path
    },
    closeEditModal() {
      this.$emit('closeModalFunction')
    },
    submitEditModal(updatedRecord) {
      this.$emit('submitModalFunction', updatedRecord)
    },
    onSubmit() {
      this.$refs.recordEditForm.validate(valid => {
        if (valid) {
          this.updateRecord()
        } else {
          console.log('error update submit!!')
          return false
        }
      })
    },
    async updateRecord() {
      const url = '/admin/cloud/uploads/' + this.recordObj.id
      try {
        const formData = new FormData()
        formData.append('media', this.form.img_file)
        formData.append('friendly_name', this.form.friendly_name)

        const cloudUpdResponse = await apiClient.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        if (cloudUpdResponse.data.error) {
          this.loading = false
          this.$message.error(cloudUpdResponse.data.error.message)
          return
        }
        this.$notification.success({
          message: 'Record successfully updated',
          description: '',
        })

        this.submitEditModal(cloudUpdResponse)
      } catch (e) {
        this.$message.error(e.response.data.error.message)
        this.loading = false
      }
    },
    deleteRecord() {
      const url = '/admin/cloud/uploads/' + this.recordObj.id
      apiClient.delete(url).then((response) => {
        const updatedRecord = response.data.data
        console.log(response.data)
        // if ((response.data.error) && (!data.error.message))
        this.$notification.success({
          message: 'Record has been successfully deleted',
          description: '',
        })
        this.submitEditModal(updatedRecord)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting record',
          description: error.message,
        })
      })
    },
    clipboardMessage(field, v) {
      this.$notification.success({
        message: field + ' successfully copied to clipboard',
        description: v,
      })
    },
    clipboardHandle(v) {
      this.$clipboard(v)
      this.clipboardMessage('', v.slice(0, 36) + '...')
    },
    fileChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      this.form.img_file = info.file.originFileObj
      this.loading = false
      if (info.file.originFileObj.type.indexOf('image') === 0) {
        getBase64(info.file.originFileObj, fileUrl => {
          this.form.img_url = fileUrl
          this.loading = false
        })
      }
      this.isImgChanged = true
    },
  },
}
</script>
<style scoped>
.ant-form-item-label {
    padding: 0px !important;
}
.link-image {
  margin-bottom: 20px;
  display: inline-block;
}
</style>
